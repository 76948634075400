import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import routePaths from "routing/routePaths";
import { toObject } from "@civicscience/chops";
import { useAuthState } from "context/AuthContext";

/**
 * Method to check if current user is impersonating or a sysadmin
 */
const isAdminOrImpersonated = (user) => user.isSysAdmin || user.isImpersonated;

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuthState();
  const { hasSignedTou = false, mustSignContract = false } = toObject(currentUser);
  const { path } = rest;
  const skipRedirect =
    (!mustSignContract && hasSignedTou) ||
    isAdminOrImpersonated(currentUser) ||
    path === routePaths.CONTRACT ||
    path === routePaths.TERMS_OF_USE;

  const { pathname } = useLocation();

  useEffect(() => {
    window.Appcues.page();
  }, [pathname]);

  return (
    <Route
      {...rest}
      render={(props) =>
        skipRedirect ? (
          <Component {...rest} {...props} />
        ) : mustSignContract ? (
          <Redirect push to={routePaths.CONTRACT} />
        ) : (
          <Redirect to={routePaths.TERMS_OF_USE} />
        )
      }
    />
  );
};

ProtectedRoute.propTypes /* remove-proptypes */ = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ProtectedRoute;
