import { PostHog } from "posthog-js/react";
import { UserWithPermissions } from "types";
import { envConfig } from "config";
import { isEnvProd } from "utils/environment";
import posthog from "posthog-js";

const getAccount = (user: UserWithPermissions) => ({
  accountId: user.account.accountId,
  name: user.account.name,
  accountType: user.account.accountType,
  updatedTime: user.account.updatedTime,
});

/**
 * Initializes Posthog.
 * This is called on app startup to initialize Posthog.
 *
 * NOTE: We opt out of event capturing in non-production environments.
 * https://posthog.com/tutorials/multiple-environments#conditional-initialization
 *
 * The above doc also recommends passing a fake key/token, however,
 * this results in a 401 response while initializing the Posthog client.
 * So we pass the real token and then continue to opt out via `loaded` below.
 */
export const initializePosthog = (): typeof posthog => {
  posthog.init(envConfig.posthogKey, {
    api_host: envConfig.posthogHost,
    person_profiles: "identified_only",
    capture_pageview: false,

    // Opt out of event capturing in non-production environments
    // https://posthog.com/tutorials/multiple-environments#conditional-initialization
    loaded: function (ph) {
      if (!isEnvProd()) {
        ph.opt_out_capturing();
        ph.set_config({ disable_session_recording: true });
      }
    },
  });

  return posthog;
};

/**
 * Bootstraps Posthog with user data.
 */
export const bootPosthog = (posthog: PostHog, user: UserWithPermissions) => {
  const { accountId, ...account } = getAccount(user);
  const name = [user.firstName, user.lastName].filter(Boolean).join(" ");

  posthog.identify(user.userId.toString(), {
    name,
    email: user.userInfo.email,
    isSysAdmin: user.isSysAdmin,
    isInternal: user.isInternal,
    isImpersonated: user.isImpersonated,
    dateRegistered: user.userInfo.dateRegistered,
    account: { accountId, ...account },
  });

  // posthog groups are an upgraded subscription feature
  posthog.group("company", accountId.toString(), account);
};

/**
 * Expected to be called when the user logs out.
 * https://posthog.com/docs/libraries/js#reset-after-logout
 */
export const logoutPosthogUser = () => {
  posthog.reset();
};
