import "fonts.js";
import "index.scss";

import App from "App";
import { BugsnagUtils } from "utils";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom";
import { initializePosthog } from "utils/analytics";

const posthog = initializePosthog();
BugsnagUtils.initialize();

const AppToRender = (
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

ReactDOM.render(AppToRender, document.getElementById("root"));
