import { ObjectValues } from "types";

const routePaths = {
  BASE: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  REQUEST_PASSWORD_RESET: "/password/request-reset",
  RESET_PASSWORD: "/password/reset",
  USER_REGISTRATION: "/registration",
  USER_REGISTRATION_CONFIRM: "/registration/confirm",
  TERMS_OF_USE: "/terms",
  CONTRACT: "/contract",
  ABOUT: "/about",
  TARGET: "/targets/:id(\\d+)",
  TARGETS: "/targets",
  DASHBOARD: "/dashboard/:uuid",
  DASHBOARD_DEFAULT: "/dashboard",
  DASHBOARD_SETTINGS: "/dashboard/:uuid/settings",
  HOME: "/home",
  QUESTIONS: "/questions",
  QUESTION: "/questions/:id",
  QUESTION_FEEDS: "/question-feeds",
  QUESTION_FEED_CREATE: "/question-feeds/create",
  QUESTION_FEED_DETAIL: "/question-feeds/:id(\\d+)",
  MATRIX_QUESTIONS: "/matrix-questions",
  MATRIX_QUESTION_DETAIL: "/matrix-questions/:id",
  MATRIX_QUESTION_CREATE: "/matrix-questions/create",
  WEIGHTING_SCHEME_CREATE: "/weighting-schemes/create",
  WEIGHTING_SCHEME_DETAIL: "/weighting-schemes/:name",
  WEIGHTING_SCHEMES: "/weighting-schemes",
  DEPLOYMENT_SCHEMES: "/deployment-schemes",
  DEPLOYMENT_SCHEME_DETAIL: "/deployment-schemes/:id",
  DEPLOYMENT_SCHEME_THEME: "/deployment-schemes/:id/theme",
  DEPLOYMENT_SCHEME_CREATE: "/deployment-schemes/create",
  SEGMENTS: "/segments",
  SEGMENT: "/segments/:uuid",
  REPORTS: "/reports",
  TOOLKIT: "/toolkit",
  PROFILE: "/profile",
  SCORECARD_DETAIL: "/reports/scorecards/:uuid",
  SCORE_TABLE_DETAIL: "/scorecards/:uuid",
  SCORE_TABLES: "/scorecards",
  STATS_TEST: "/scorecards/stats-test",
  DEEP_PROFILE_CREATE: "/reports/deep-profiles/create",
  DEEP_PROFILE: "/reports/deep-profiles/:uuid",
  MULTI_TIMEVIEW_CREATE: "/reports/multi-timeviews/create",
  MULTI_TIMEVIEW: "/reports/multi-timeviews/:uuid",
  MULTI_SCORE_CREATE: "/reports/multi-scores/create",
  MULTI_SCORE: "/reports/multi-scores/:id(\\d+)",
  SCORECARD_CREATE: "/reports/scorecards/create",
  CHECKBOX_QUESTIONS: "/checkbox-questions",
  CHECKBOX_QUESTION: "/checkbox-questions/:id",
  CHECKBOX_GROUPS: "/checkbox-groups",
  CHECKBOX_GROUP_DETAIL: "/checkbox-groups/:id",
  CHECKBOX_GROUP_CREATE: "/checkbox-groups/create",
  CHECKBOX_GROUP_TRANSLATION: "/checkbox-groups/:id/translation",
  TAGS: "/tags",
  TAG_DETAIL: "/tags/:id",
  SYSADMIN: "/sysadmin",
  TRENDING_QUESTIONS: "/sysadmin/trending-questions",
  NUGGET_PRESET_TRAIT_BUSINESS: "/nugget-preset-traits",
  STAR_RATING_QUESTIONS: "/star-rating-questions",
  CROSSTABS: "/crosstabs",
  CROSSTAB_CREATE: "/crosstabs/create",
  CROSSTAB: "/crosstabs/:id",
  CROSSTAB_CREATE_POC: "/reports/crosstab-poc/create",
} as const;

// Union of string literals representing our route paths
export type RoutePathType = ObjectValues<typeof routePaths>;

export default routePaths;
