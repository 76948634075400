const Analytics = {
  DashboardList: "view-dashboard-list",
  LoadDashboard: "view-dashboard",
  CreateDashboard: "create-dashboard",
  SegmentList: "view-segment-list",
  LoadSegment: "view-segment",
  StartedNewSegment: "started-new-segment",
  FinishedNewSegment: "finished-new-segment",
  QuestionList: "view-question-list",
  LoadQuestion: "view-question",
  QuestionResults: "view-question-results",
  QuestionCompare: "view-question-compare",
  QuestionInsights: "view-question-insights",
  StartedNewQuestion: "started-new-question",
  FinishedNewQuestion: "finished-new-question",
  StartedNewMatrixQuestion: "started-new-matrix-question",
  FinishedNewMatrixQuestion: "finished-new-matrix-question",
  TargetList: "view-target-list",
  LoadTarget: "view-target",
  FinishedNewTarget: "finished-new-target",
  EditTarget: "finished-editing-target",
  QuestionFeedList: "view-question-feed-list",
  LoadQuestionFeed: "view-question-feed",
  ReportList: "view-report-list",
  LoadCrosstab: "view-crosstab",
  LoadDeepProfile: "view-deepprofile",
  LoadMultiScore: "view-multiscore",
  LoadMultiTimeview: "view-multitimeview",
  LoadScorecard: "view-scorecard",
  StartedNewDeepProfile: "started-new-deepprofile",
  FinishedNewDeepProfile: "finished-new-deepprofile",
  StartedNewMultiTimeview: "started-new-multitimeview",
  FinishedNewMultiTimeview: "finished-new-multitimeview",
  StartedNewCrosstab: "started-new-crosstab",
  FinishedNewCrosstab: "finished-new-crosstab",
  StartedCrosstabColumnsAndRows: "started-crosstab-columns-and-rows",
  FinishedCrosstabColumnsAndRows: "finished-crosstab-columns-and-rows",
  StartedNewMultiScore: "started-new-multiscore",
  FinishedNewMultiScore: "finished-new-multi-score",
  Home: "view-home",
  LoadProfile: "view-profile",
  LOGIN: "is2-explicit-login", // User logs themself in
};

export default Analytics;
